<template>
  <div class="main-body">
    <page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
  </div>
</template>
<script>
  //PRUEBA DE CAMBIOS DE LAPTOP DE GUILLERMO
  //PRUEBA DE CAMBIOS 2
  export default {
    data() {
      return {
        dataModel: {
          name: "home", //AQUI EN INGLES SINGULAR
          alias: "Escritorio",
          icon: "ri-home-8-line",
        },
        page: {
          header: {
            menu: ["Escritorio"],
          },
          buttons: {
            create: false,
            import: false,
            export: false,
          },
        },
      };
    },
  };
</script>
